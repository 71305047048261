@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#skills::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
}

#additional::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
}


#contact1::before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #ff43e1 0%, #6630ff 66%, #1472ff 100%);
  width: 100px;
  height: calc(100% + 100px);
  top: -40px;
  border-radius: 100%;
  right: -15px;
  z-index: -1;
}
#service1::before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #ff43e1 0%, #6630ff 66%, #1472ff 100%);
  width: 100px;
  height: calc(100% + 100px);
  top: 0px;
  border-radius: 100%;
  right: 1px;
  z-index: -1;
}
#service2::before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #ff43e1 0%, #6630ff 66%, #1472ff 100%);
  width: 100px;
  height: calc(100% + 100px);
  bottom: 0px;
  border-radius: 100%;
  left: 1px;
  z-index: -1;
}
#home1::before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #ff43e1 0%, #6630ff 66%, #1472ff 100%);
  width: 100px;
  height: calc(100% + 100px);
  top: -40px;
  border-radius: 100%;
  right: -20px;
  z-index: -1;
}
#home2::before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #ff43e1 0%, #6630ff 66%, #1472ff 100%);
  width: 100px;
  height: calc(100% + 100px);
  bottom: -60px;
  border-radius: 100%;
  left: -20px;
  z-index: -1;
}

#service3::before {
  content: '';
  position: absolute;
  background: linear-gradient(to bottom, #ff43e1 0%, #6630ff 50%, #1472ff 100%);
  width: 100px;
  height: calc(100% + 100px);
  bottom: -294px;
  border-radius: 100%;
  left: 600px;
  z-index: -1;
}

.project-title::after {
  content: '';
  position: absolute;
  background: linear-gradient(to right, #ff43e1 0%, #6630ff 52%, #1472ff 100%);
  width: 120px;
  height: 3px;
  top: 50px;
  left: 65px;
}

.project-title::after {
  content: '';
  position: absolute;
  background: linear-gradient(to right, #ff43e1 0%, #6630ff 52%, #1472ff 100%);
  width: 120px;
  height: 3px;
  top: 50px;
  left: 65px;
}
.footer-title::after {
  content: '';
  position: absolute;
  background: linear-gradient(to right, #ff43e1 0%, #6630ff 52%, #1472ff 100%);
  width: 120px;
  height: 3px;
  top: 50px;
  left: 0px;
}

@media only screen and (max-width: 600px) {
  .project-title::after {
    top: 50px;
    left: 35px;
  }
}

.contact-title::after{
  content: '';
  position: absolute;
  background: linear-gradient(to right, #ff43e1 0%, #6630ff 52%, #1472ff 100%);
  width: 120px;
  height: 3px;
  top: 50px;
  left: 0px;
}

/* the slides */
.slick-slide > div {
  margin: 0 10px;
}

/* the parent */
.slick-list {
  margin: 0 -10px;
}

.slick-dots li button {
  opacity: 1;
  color: #fff;
}
